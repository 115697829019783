// 新logo的所有Css文件
.logoLink {
  @apply inline-block;
}

.logoWrapper {
  @apply flex-1 text-center align-middle text-[0px] leading-none xl:flex-none;
  @apply xl:absolute xl:left-10 xl:top-0 xl:flex xl:h-20 xl:items-center xl:whitespace-nowrap;

  a {
    svg {
      @apply h-[24px] xl:h-[40px];
    }
  }
}
