.lang {
  padding-right: 30px;
}

.langWrap {
  margin-bottom: 33px;
}

.item {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
  &.itemCurrent {
    color: #000;
  }
}
