.link-item {
  @apply relative cursor-pointer whitespace-nowrap text-gray-90 transition-all;
  padding: 8px 8px 8px 36px;
  line-height: 24px;
  border-radius: 6px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    // font-weight: 500;
    .link-item-title {
      // font-weight: 500;
    }
  }

  &-title {
    @apply text-base text-gray-90;
    line-height: 24px;
    display: block;
  }

  &-icon {
    line-height: 0;
    img {
      width: 76px;
      height: 76px;
    }
  }
}
.followMe {
  @apply relative flex h-20 items-center xl:mr-6 2xl:mr-10;
  &.current {
    .arrow {
      transform: rotateZ(180deg);
    }
  }
}
.arrow {
  @apply relative inline-block;
  transition: all 300ms ease-in-out;
  font-size: 0;
  vertical-align: middle;
  top: -1px;
}

.toggle-panel {
  @apply absolute pt-1;
  z-index: 10000;
  left: -10px;
  top: 100%;
}

.toggle-panel-bg {
  background: #fff;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.toggle-panel-content {
  position: relative;
  padding: 4px;
  z-index: 2;
}

.block {
  display: block;
}

.none {
  display: none;
}

.langWrapDark {
  color: rgba(255, 255, 255, 0.45);
}
.langWrapLight {
  @apply text-gray-45;
  .langDarkCurrent {
    @apply text-gray-90;
  }
}

.langDarkCurrent {
  color: #fff;
}
.langLightCurrent {
  @apply text-gray-90;
}

.followMeTitle {
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 14px;
  color: #000;
  line-height: 18px;
}
.followMeIcon {
  @apply flex;
  margin-bottom: 12px;
}

.langSpace {
  @apply px-0.625r xl:px-0.25r;
}

.logoModal {
  :global {
    .ant-modal-content {
      @apply bg-white p-6;
      border-radius: 16px;
      width: 208px;
      box-shadow: none;
    }
  }
  line-height: 0;
}
.logoModalClose {
  @apply relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;

  &:before {
    display: block;
    width: 50px;
    height: 50px;
    background-size: 100%;
    background-position: center;
    content: ' ';
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNzIiIGhlaWdodD0iNzIiIHZpZXdCb3g9IjAgMCA3MiA3MiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxjaXJjbGUgc3Ryb2tlLW9wYWNpdHk9Ii4yNSIgc3Ryb2tlPSIjRkZGIiBmaWxsLW9wYWNpdHk9Ii4xNDciIGZpbGw9IiNGRkYiIGN4PSIzNiIgY3k9IjM2IiByPSIzNS41Ii8+PHBhdGggZD0iTTM4LjMyNCAzNi4yMDNsMTIuMDIxIDEyLjAyYTEuNSAxLjUgMCAxIDEtMi4xMjIgMi4xMjJsLTEyLjAyLTEyLjAyMi0xMi4wMiAxMi4wMjJhMS41MDEgMS41MDEgMCAwIDEtMi4xMjItMi4xMjNsMTIuMDIyLTEyLjAxOS0xMi4wMjItMTIuMDJhMS41IDEuNSAwIDAgMSAyLjEyMi0yLjEyMmwxMi4wMiAxMi4wMjIgMTIuMDItMTIuMDIyYTEuNSAxLjUgMCAwIDEgMi4xMjIgMi4xMjJsLTEyLjAyMSAxMi4wMnoiIGZpbGwtb3BhY2l0eT0iLjg1IiBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L2c+PC9zdmc+');
  }
}

.btn {
  background-color: transparent;
}

.logoFooter {
  @apply mt-3 text-center text-xs leading-5 text-black-45;
}

.logoHeader {
  font-size: 18px;
  @apply mb-3 flex items-center justify-between font-bold leading-6 text-black;
}
