.item {
  @apply block h-0.5 w-4 rounded-sm;
}
.item:not(:last-of-type) {
  @apply mb-1;
}

.dark {
  background-color: #fff;
  :global(.with-filter-background) & {
    @apply bg-black;
  }
}

.light {
  @apply bg-black;
}

.toggle {
  @apply relative flex cursor-pointer flex-col bg-clip-padding;
  &:after {
    content: '';
    position: absolute;
    top: -20px;
    right: -20px;
    bottom: -20px;
    left: -20px;
  }
}
