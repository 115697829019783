@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@supports (-webkit-touch-callout: none) {
  .h-screen {
    height: -webkit-fill-available;
  }
}
// @font-face {
//   font-family: MTTi;
//   src: url('https://s3plus.meituan.net/v1/mss_792eb492f090427ab38638303ff58ca0/fawususong-prod/Meituan%20Type-Regular.TTF')
//       format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }

// // 繁体 https://s3plus.meituan.net/v1/mss_f5ecef526b384cbaa1117230d53b4bd6/smart/JIvkuT_fb55qi.ttf
// @font-face {
//   font-family: MTTiHK;
//   src: url('https://s3plus.meituan.net/v1/mss_f5ecef526b384cbaa1117230d53b4bd6/smart/JIvkuT_fb55qi.ttf')
//     format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
:root {
  --adm-radius-s: 4px;
  --adm-radius-m: 8px;
  --adm-radius-l: 12px;
  --adm-font-size-1: 9px;
  --adm-font-size-2: 10px;
  --adm-font-size-3: 11px;
  --adm-font-size-4: 12px;
  --adm-font-size-5: 13px;
  --adm-font-size-6: 14px;
  --adm-font-size-7: 15px;
  --adm-font-size-8: 16px;
  --adm-font-size-9: 17px;
  --adm-font-size-10: 18px;
  --adm-color-primary: #1677ff;
  --adm-color-success: #00b578;
  --adm-color-warning: #ff8f1f;
  --adm-color-danger: #ff3141;
  --adm-color-white: #fff;
  --adm-color-text: #333;
  --adm-color-text-secondary: #666;
  --adm-color-weak: #999;
  --adm-color-light: #ccc;
  --adm-color-border: #eee;
  --adm-color-box: #f5f5f5;
  --adm-color-background: #fff;
  --adm-font-size-main: var(--adm-font-size-5);
  --adm-font-family: 'PingFang SC', 'Microsoft YaHei', 'Helvetica Neue', 'Helvetica', 'Arial', 'Heiti SC',
    'Hiragino Sans GB', 'Sans-serif';
  --adm-border-color: var(--adm-color-border);
}

html {
  -webkit-tap-highlight-color: transparent;
  // scroll-behavior: smooth;
  height: 100vh;
  // antd popup z-index
  --adm-popup-z-index: 10000;
  font-size: 16px;
}
@font-face {
  font-family: MTTiJ;
  // 使用3500个常用字剔除冗余后的版本（TTF从1.8M优化到了800KB，WOFF2从800KB优化到了400KB）
  // TIPS：如遇字体无法加载，替换为下面的完全版本
  src: url('https://s3plus.meituan.net/smart/meituan-type-daily-words.woff2') format('woff2'),
    url('https://s3plus.meituan.net/smart/daily-words-mt-type.ttf') format('truetype');
  // src: url('https://s3plus.meituan.net/smart/meituantype-regular-webfont.woff2') format('woff2'),
  //   url('https://s3plus.meituan.net/v1/mss_792eb492f090427ab38638303ff58ca0/fawususong-prod/Meituan%20Type-Regular.TTF')
  //     format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html[lang^='zh-CN'],
html[lang^='en-US'] {
  .mt-font {
    font-family: 'MTTiJ', 'PingFang SC', 'Microsoft YaHei UI', 'Microsoft YaHei', 'Source Han Sans CN';
  }
  .mt-num-font {
    font-family: 'MTTiJ', 'PingFang SC', 'Microsoft YaHei UI', 'Microsoft YaHei', 'Source Han Sans CN';
  }
}

html[lang^='zh-HK'] {
  @font-face {
    font-family: MTTiF;
    src: url('https://s3plus.meituan.net/smart/meituan-type-hk.woff2') format('woff2'),
      url('https://s3plus.meituan.net/v1/mss_f5ecef526b384cbaa1117230d53b4bd6/smart/JIvkuT_fb55qi.ttf')
        format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  .mt-font {
    font-family: 'MTTiF', 'PingFang SC', 'Microsoft YaHei UI', 'Microsoft YaHei', 'Source Han Sans CN';
  }
  .mt-num-font {
    font-family: 'MTTiJ', 'PingFang SC', 'Microsoft YaHei UI', 'Microsoft YaHei', 'Source Han Sans CN';
  }
}

html[lang^='zh-CN'],
html[lang^='zh-HK'] {
  --gy-label-mo-width: 75px;
  --gy-label-pc-width: 88px;
  .text-align-justify {
    text-align: justify;
    word-break: break-word;
  }

  .block-zh {
    display: block;
  }
}

html[lang^='en-US'] {
  --gy-label-mo-width: 124px;
  --gy-label-pc-width: 144px;
  body {
    @apply font-meituanEn;
  }
  .text-align-justify {
    text-align: left;
    word-break: break-word;
  }
  .hidden-en {
    display: none;
  }
}

body {
  @apply font-meituan;
  position: relative;
  width: 100%;
  color: #111925;
  -webkit-touch-callout: none;
  background-color: #f3f3f3;
}

td,
th {
  padding: 4px 8px 4px 4px;
  text-align: left;
}

th {
  font-weight: 600;
}
/**
* 处理全局video的移动端样式问题
*/
video::slotted::-webkit-media-controls-container {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
  -webkit-appearance: none !important;
}

// 过渡动画相关的CSS
.collapsed-enter {
  opacity: 0;
  &-active {
    transition: all 300ms ease-in-out;
  }
  &-to {
    opacity: 1;
  }
}

.collapsed-leave {
  opacity: 1;
  &-active {
    transition: all 300ms ease-in-out;
  }
  &-to {
    opacity: 0;
  }
}
// 下来菜单动画
.dropdown {
  &-enter {
    opacity: 0;
    &-active {
      transition: all 300ms ease-in-out;
    }
    &-to {
      opacity: 1;
    }
  }
  &-leave {
    opacity: 1;
    &-active {
      transition: all 300ms ease-in-out;
    }
    &-to {
      opacity: 0;
    }
  }
}

// header组件
.header-dark {
  --header-text-color: #fff;

  .header-links-color {
    color: var(--header-text-color);
  }
}
.header-light {
  --header-text-color: #111925;

  .header-links-color {
    color: var(--header-text-color);
  }
}

// tooltips的meituanlight 样式

$tooltips-color: #fff;
html body {
  .tippy-box {
    border-radius: 6px;

    &[data-theme='lg'] {
      border-radius: 16px !important;
    }

    &[data-placement^='right'] {
      > .tippy-svg-arrow {
        left: 0.5px;
      }
    }
  }
}

.tippy-box[data-theme~='meituan'],
.tippy-box[data-theme~='lg'] {
  background-color: $tooltips-color;
  box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.1);
  color: #000;
  > .tippy-content {
    padding: 8px;
  }
  .tippy-svg-arrow {
    fill: #fff;
  }
}
.tippy-box[data-theme~='meituan'][data-placement^='top'] > .tippy-arrow::before {
  border-top-color: $tooltips-color;
}
.tippy-box[data-theme~='meituan'][data-placement^='bottom'] > .tippy-arrow::before {
  border-bottom-color: $tooltips-color;
}
.tippy-box[data-theme~='meituan'][data-placement^='left'] > .tippy-arrow::before {
  border-left-color: $tooltips-color;
}
.tippy-box[data-theme~='meituan'][data-placement^='right'] > .tippy-arrow::before {
  border-right-color: $tooltips-color;
}

.box-s {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
}
