.mnav-menu-wrapper {
  @apply fixed left-0 top-0 w-[0px] xl:hidden;
  z-index: 30000;
}

.mnav-menu-body {
  @apply fixed bottom-0 left-[-220px] top-0 z-20 bg-white opacity-0  xl:hidden;
  @apply w-[220px] overflow-hidden pt-[40px] transition duration-300;
}

.mnav-menu-body-link {
  padding-left: 30px;
  padding-bottom: 40px;
  display: block;
}

.mnav-menu-wrap {
  position: relative;
  height: calc(100% - 72px);
}
.mnav-menu-body-wrap {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
}

.mnav-menu-body-inner {
  padding-left: 30px;
  margin-bottom: 92px;
}

.mnav-menu-footer {
  padding-left: 30px;
  padding-bottom: 50px;
  transition: all 300ms ease-in-out;
}

.mnav-menu-wrapper.toggle-open {
  .mnav-menu-body {
    @apply opacity-100;
    transform: translateX(100%);
  }
  .mnav-menu-mask {
    @apply opacity-100;
    visibility: visible;
  }
}

.mnav-menu-wrap.toggle-open {
  .mnav-menu-footer {
    opacity: 0;
    transform: translateX(-50%);
  }
}

.mnav-menu-mask {
  @apply fixed bottom-0 left-0 right-0 top-0 opacity-0 transition duration-300 xl:hidden;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;
}

// .mnav-menu-wrapper.toggle-open {
//   .mnav-menu-mask {
//     @apply opacity-100;
//     visibility: visible;
//   }
// }
