.header {
  @apply fixed left-0 right-0 z-[10009] flex h-15 items-center lg:z-[10000] xl:block xl:h-20;
  top: 0;

  --header-text-color: #111925;
  :global(.header-links-color) {
    color: var(--header-text-color);
  }
  &:global(.header-dark) {
    --header-text-color: #fff;
  }
}

.headerBg {
  @apply backdrop-blur-0 lg:backdrop-blur-[28px];
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  z-index: -1;
  box-shadow: none;
  :global(.header-light) > & {
    @apply bg-white lg:bg-[rgba(255,255,255,0.85)];
  }
  :global(.header-dark) > & {
    background-color: rgba(16, 11, 0, 0.85);
  }
  :global(.with-filter-background) > & {
    @apply bg-white shadow-none lg:bg-[rgba(255,255,255,0.85)]	lg:shadow-[0_4px_10px_0_rgba(0,0,0,0.08)];
  }
}
