.nav-panel {
  @apply absolute pt-1;
  left: -8px;
  top: 100%;
  z-index: 1000;
}

.nav-panel-bg {
  background: #fff;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.nav-panel-content {
  position: relative;
  z-index: 2;
}

.none {
  display: none;
}

.block {
  display: block;
}
