.nav-item {
  @apply relative flex items-center xl:px-2 2xl:px-6;
  height: 80px;

  &.nav-item-current {
    .slider {
      &:before {
        width: 100%;
      }
    }
  }
  &.nav-item-hover {
    .slider {
      &:before {
        width: 0;
      }
      &:after {
        width: 24px;
      }
    }
  }

  .slider {
    @apply absolute;
    width: 24px;
    height: 5px;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 3px;
    &:after {
      @apply absolute left-0 top-0 bg-yellow-500;
      @apply transition-all duration-300;
      display: block;
      content: ' ';
      width: 0;
      height: 100%;
      border-radius: 3px;
    }
    &:before {
      @apply absolute left-0 top-0 bg-yellow-500;
      display: block;
      content: ' ';
      width: 0;
      height: 100%;
      border-radius: 3px;
    }
  }
}

.sliderWrap {
  position: relative;
}
.arrow {
  @apply inline-block align-middle;
  transition: all 300ms ease-in-out;

  &.current {
    transform: rotateZ(180deg);
  }
}

.nav-item-a-link {
  @apply relative;
  z-index: 1000;
  border: 18px solid transparent;
  border-left: none;
  border-right: none;
  background-clip: padding-box;
  svg {
    @apply relative;
    top: -1px;
  }
}
