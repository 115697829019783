.arrow {
  @apply relative inline-block;
  transition: all 300ms ease-in-out;
  font-size: 0;
  vertical-align: middle;
  top: -1px;
}

.dropMenu {
  @apply absolute pt-1;
  visibility: hidden;
  opacity: 0;
  right: 0;
  top: 100%;
  .dropMenuInner {
    @apply overflow-hidden rounded-lg  bg-white p-1;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  }
  .itemborder {
    @apply mx-2 my-1 border-b-1;
    border-color: transparent;
    border-color: rgba(0, 0, 0, 0.04);
  }
  &.dropDownCurrent {
    visibility: visible;
    transition: all 300ms ease-in-out;
    opacity: 1;
    height: auto;
  }
}
.menuItem {
  @apply flex cursor-pointer justify-between p-2 text-base;
  border-radius: 6px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.langContainer {
  @apply relative flex h-20 items-center;
  &.current {
    .arrow {
      transform: rotateZ(180deg);
    }
  }
}

.outlink {
  @apply flex cursor-pointer items-center justify-between whitespace-nowrap rounded border-1 px-[10px] py-[6px] text-sm;
  @apply lg:min-w-[170px] lg:border-0 lg:border-none lg:px-2 lg:pb-2 lg:pt-3 lg:text-base;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}
