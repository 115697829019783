.mt-rich-editor-view > :first-child {
  margin-top: 0 !important;
}

.mt-rich-editor-view p img,
.mt-rich-editor-view video {
  margin: auto;
}

.mt-rich-editor-view .mt-image-remark-container {
  text-align: center;
  margin: 20px 0;
}

.mt-rich-editor-view .mt-image-remark-container.have-remark-c {
  margin: 20px 0;
}

.mt-image-remark-container .mt-rich-image {
  max-width: 100%;
  max-height: 100%;
  display: inline-block !important;
}

/* 覆盖默认宽度 */
.mt-image-w-full {
  min-width: 100% !important;
  max-width: 100% !important;
}
.mt-image-w-80 {
  min-width: 80% !important;
  max-width: 80% !important;
}
.mt-image-w-60 {
  min-width: 60% !important;
  max-width: 60% !important;
}

.mt-image-remark-container .mt-image-remark {
  padding-top: 12px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: rgba(17, 25, 37, 0.45);
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
}

/*标题*/
.mt-rich-editor-view .mt-rich-text-1 {
  font-family: PingFangSC-Medium;
  font-size: 18px;
  color: #111925;
  letter-spacing: 0;
  text-align: justify;
  line-height: 28px;
  font-weight: 500;
  margin: 24px 0 12px;
}

.mt-rich-editor-view p {
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #343b45;
  letter-spacing: 0;
  text-align: justify;
  line-height: 28px;
  font-weight: 400;
  margin: 12px 0 !important;
}

/* wangEditor common css*/
.mt-rich-editor-view a {
  background-color: transparent;
  color: #166ff7;
}

/*.mt-rich-editor-view p,*/
/*.mt-rich-editor-view li {*/
/*  white-space: pre-wrap; !* 保留空格 *!*/
/*}*/

.mt-rich-editor-view blockquote {
  /* border-left: 4px solid rgba(17, 25, 37, 0.15); */
  padding: 5px 10px;
  padding-left: 16px;
  margin: 10px 0;
  position: relative;
  color: rgba(17, 25, 37, 0.65);
  word-break: break-all;
  /* background-color: #f1f1f1; */
}
.mt-rich-editor-view blockquote:after {
  position: absolute;
  background: rgba(17, 25, 37, 0.15);
  width: 4px;
  border-radius: 1.5px;
  left: 0;
  bottom: 0;
  top: 0;
  content: ' ';
  /* background-color: #f1f1f1; */
}

.mt-rich-editor-view code {
  font-family: monospace;
  background-color: #eee;
  padding: 3px;
  border-radius: 3px;
}

.mt-rich-editor-view pre > code {
  display: block;
  padding: 10px;
}

.mt-rich-editor-view table {
  border-collapse: collapse;
}

.mt-rich-editor-view td,
.mt-rich-editor-view th {
  border: 1px solid #ccc;
  min-width: 50px;
  height: 20px;
}

.mt-rich-editor-view th {
  background-color: #f1f1f1;
}

.mt-rich-editor-view ul,
.mt-rich-editor-view ol {
  list-style: revert;
  padding-left: 21px;
  margin: 12px 0;
  margin-left: -9px;
}
.mt-rich-editor-view ul li,
.mt-rich-editor-view ol li {
  padding: 6px 0;
  padding-left: 4px;
}
.mt-rich-editor-view ul li::marker {
  font-size: 10px;
  vertical-align: middle;
}

.mt-rich-editor-view input[type='checkbox'] {
  margin-right: 5px;
}

/*h5*/
@media (max-width: 768px) {
  .mt-rich-editor-view .mt-rich-text-1 {
    font-size: 16px;
  }
  .mt-rich-editor-view p {
    font-size: 14px;
  }

  .mt-image-w-full,
  .mt-image-w-80,
  .mt-image-w-60 {
    min-width: 100% !important;
    max-width: 100% !important;
  }
}
