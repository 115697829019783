.donation {
  @apply flex flex-col items-center justify-center;
  border-radius: 8px;
  background: #fff;
  box-sizing: border-box;
  border: 0.5px solid #ccc;
  padding: 8px;
  width: max-content;
  max-width: 200px;
}

.donationIcon {
  display: block;
}

.donationTitle {
  font-size: 12px;
  text-align: center;
}
