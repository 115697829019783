.mnav-menu-child {
  @apply absolute bottom-0 left-0 top-0;
  width: 220px;
  padding-left: 30px;
  background-color: #fff;
  overflow: auto;
  transform: translateX(220px);
  transition: transform 0.3s ease-in-out;
  z-index: 2;
}

.mnav-item-li {
  line-height: 18px;
  font-size: 14px;
  padding-bottom: 15px;
}
.mnav-item-li:not(:first-of-type) {
  margin-top: 15px;
}
.mnav-item-link-a {
  @apply flex items-center justify-between;
  transition: all 300ms ease-in-out;
  color: #000;
}
.mnav-ul.mnav-ul-toggle-open {
  .mnav-item-link-a {
    opacity: 0;
    transform: translateX(-50%);
  }
}
.mnav-item-arrow {
  padding-right: 16px;
}

.toggle-open .mnav-menu-child {
  transform: translateX(0);
}

.mnav-submenu-title {
  @apply flex items-center justify-start;
  font-size: 1rem;
  line-height: 1.4rem;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid rgba(17, 25, 37, 0.06);
  margin-bottom: 1.25rem;
  font-weight: 500;
  margin-right: 30px;
}

.subTitle {
  margin-left: 5px;
}
.subLinks {
  color: #000;
}

.baogao {
  border-top: 1px solid rgba(17, 25, 37, 0.06);
  padding-top: 20px;
  margin-right: 30px;
  padding-bottom: 40px;
}
