.followMe {
  @apply hidden lg:block;
}

.linkItem {
  @apply relative flex items-center lg:mb-4;
}
.linkItemWrap {
  @apply flex hover:underline;
  white-space: nowrap;
  &:hover {
    .title {
      @apply text-gray-90;
    }
  }
}
.linkAppItemWrap {
  @apply relative flex items-center lg:mb-4;
}
.iconWrap {
  width: 90px;
  height: 90px;
}

.title {
  @apply cursor-pointer text-sm;
  @apply lg:flex lg:text-gray-65;
  margin-left: 8px;
}

.mfollowMe {
  @apply flex lg:hidden;
}
.miconWrap {
  margin-left: 12px;
  display: flex;
  @apply items-center;
}
// 黑色主题
.followMe-dark {
  .title {
    color: rgba(255, 255, 255, 0.65);
    color: var(--dark-text-color);
  }
  .linkItemWrap {
    &:hover {
      .title {
        color: rgba(255, 255, 255, 0.85);
        text-decoration: underline;
      }
    }
  }
}
