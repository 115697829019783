.cookie-container {
  @apply fixed bottom-0 left-0 z-[10001];
  @apply w-full overflow-hidden px-5 pb-3.375r pt-2.03r text-white lg:px-0;
  @apply lg:py-8;

  // background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85));
  // filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background: rgba(0, 0, 0, 0.85);
  .cookie-title {
    @apply mb-0.875r text-lg font-medium;
    @apply lg:mb-8 lg:text-2xs lg:leading-5;
  }
  .cookie-content {
    @apply mb-7.5 text-sm font-medium;
    @apply lg:mb-8 lg:text-base;
    .light-text {
      @apply text-meituan;
    }
  }
  .cookie-btn-ground {
    @apply flex flex-col space-y-3;
    @apply lg:flex-row lg:space-x-5 lg:space-y-0;
    .cookie-btn {
      @apply flex h-10 w-full cursor-pointer items-center justify-center rounded-md border border-white text-sm font-medium;
      @apply lg:h-10 lg:w-7.63r lg:text-sm;
    }
    .cookie-btn-accept {
      @apply bg-white text-black;
    }
    .cookie-btn-reject {
      @apply bg-transparent text-white;
    }
  }
}

// 毛玻璃兼容性处理
// .cookie-container::before {
//   content: '';
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background: rgba(0, 0, 0, 0.85);
//   filter: blur(13px);
//   z-index: -1;
// }
