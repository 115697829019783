.subnav-wrap {
  & + & {
    margin-left: 80px;
  }
}

.subnav-menu-header {
  @apply mb-7 font-semibold text-gray-90;
  font-size: 18px;
  white-space: nowrap;
  padding-bottom: 14px;
  border-bottom: 1px solid rgba(17, 25, 37, 0.1);
}

.subnav-menu-li {
  border-radius: 6px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}
